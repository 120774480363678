import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Card, Container } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import axiosInstance from '../../services/axiosConfig';
import { useAuth } from '../../../AuthContext';
import './api.css';

const ApiManagement = () => {
  const [apis, setApis] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newApi, setNewApi] = useState({
    name: '',
    apiKey: '',
    apiSecret: '',
    password: '',
    exchange: '',
    environment: '',
  });
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      fetchApis();
    }
  }, [isLoggedIn]);

  const fetchApis = async () => {
    try {
      const response = await axiosInstance.get('/api/user/apis');
      setApis(response.data);
    } catch (error) {
      console.error('Error fetching APIs:', error.response?.data || error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewApi(prev => ({
      ...prev,
      [name]: value,
      // Reset password when changing exchange
      ...(name === 'exchange' && { password: '' }),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/api/user/apis', newApi);
      setNewApi({ name: '', apiKey: '', apiSecret: '', password: '', exchange: '', environment: '' });
      setShowModal(false);
      fetchApis();
    } catch (error) {
      console.error('Error adding API:', error.response?.data || error.message);
    }
  };

  const handleDelete = async (apiId) => {
    try {
      await axiosInstance.delete(`/api/user/apis/${apiId}`);
      fetchApis();
    } catch (error) {
      console.error('Error deleting API:', error.response?.data || error.message);
    }
  };

  return (
    <Container fluid className="p-4">
      <div className="header">
        <h1 className="text-left">API Management</h1>
        <Button
          variant="warning"
          className="mt-2"
          style={{ maxWidth: '200px', backgroundColor: '#f7a600', borderColor: '#f7a600' }}
          onClick={() => setShowModal(true)}
        >
          <PlusCircle className="me-2" /> Create New API
        </Button>
      </div>

      <div className="api-container">
        {apis.map((api) => (
          <Card key={api._id} bg="dark" text="white" className="api-card">
            <Card.Body>
              <div className="api-info">
                <Card.Title>{api.name} ({api.exchange} - {api.environment})</Card.Title>
              </div>
              <div className="api-actions">
                <Button
                  variant="danger"
                  onClick={() => handleDelete(api._id)}
                  style={{ backgroundColor: '#dc3545', borderColor: '#dc3545', maxWidth: '200px' }}
                >
                  Delete
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} contentClassName="bg-dark text-white">
        <Modal.Header closeButton>
          <Modal.Title>Add New API</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Exchange</Form.Label>
              <Form.Select name="exchange" value={newApi.exchange} onChange={handleInputChange} required>
                <option value="">Select exchange</option>
                <option value="binance">Binance</option>
                <option value="bybit">Bybit</option>
                <option value="okx">Okx</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Environment</Form.Label>
              <Form.Select name="environment" value={newApi.environment} onChange={handleInputChange} required>
                <option value="">Select environment</option>
                <option value="demo">Demo</option>
                <option value="testnet">Testnet</option>
                <option value="live">Live</option>

              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>API Name</Form.Label>
              <Form.Control type="text" name="name" value={newApi.name} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>API Key</Form.Label>
              <Form.Control type="text" name="apiKey" value={newApi.apiKey} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>API Secret</Form.Label>
              <Form.Control type="password" name="apiSecret" value={newApi.apiSecret} onChange={handleInputChange} required />
            </Form.Group>
            {newApi.exchange === 'okx' && (
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" name="password" value={newApi.password} onChange={handleInputChange} required />
              </Form.Group>
            )}
            <Button variant="primary" type="submit" className="w-100 mt-3">
              Save API
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ApiManagement;