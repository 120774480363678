import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import axiosInstance from '../../services/axiosConfig';

const BotConditionsModal = ({ show, onHide, botId, isEditing = false, onSave }) => {
  const [enabledFields, setEnabledFields] = useState({
    takeProfit: false,
    stopLoss: false,
    upperLimit: false,
    lowerLimit: false,
    runTime: false
  });

  const [conditions, setConditions] = useState({
    takeProfit: '',
    stopLoss: '',
    upperLimit: '',
    lowerLimit: '',
    runTime: ''
  });

  useEffect(() => {
    if (isEditing && botId) {
      const fetchConditions = async () => {
        try {
          const response = await axiosInstance.get(`/bot/conditions/${botId}`);
          if (response.data.success && response.data.conditions) {
            setConditions(response.data.conditions);
            // Enable fields that have values
            const enabled = Object.keys(response.data.conditions).reduce((acc, key) => ({
              ...acc,
              [key]: !!response.data.conditions[key]
            }), {});
            setEnabledFields(enabled);
          }
        } catch (error) {
          console.error('Error fetching bot conditions:', error);
        }
      };
      fetchConditions();
    }
  }, [botId, isEditing]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submittedConditions = Object.keys(conditions).reduce((acc, key) => {
      if (enabledFields[key]) {
        acc[key] = conditions[key];
      }
      return acc;
    }, {});

    try {
      if (isEditing) {
        const response = await axiosInstance.post(`/bot/set-conditions/${botId}`, submittedConditions);
        if (response.data.success) {
          onHide();
        }
      } else {
        if (onSave) {
          onSave(submittedConditions);
        }
        onHide();
      }
    } catch (error) {
      console.error('Error saving conditions:', error);
    }
  };

  const handleChange = (e) => {
    setConditions({
      ...conditions,
      [e.target.name]: e.target.value ? parseFloat(e.target.value) : ''
    });
  };

  const toggleField = (fieldName) => {
    setEnabledFields(prev => ({
      ...prev,
      [fieldName]: !prev[fieldName]
    }));
  };

  const modalStyle = {
    background: '#2d3748',
    color: 'white'
  };

  const formGroupStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton style={modalStyle}>
        <Modal.Title style={{ color: 'white' }}>
          {isEditing ? 'Edit Bot Conditions' : 'Additional Bot Conditions'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={modalStyle}>
        <div className="mb-3" style={{ color: '#f6465d' }}>
          Only checked fields will be submitted
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" style={formGroupStyle}>
            <Form.Check
              type="checkbox"
              checked={enabledFields.takeProfit}
              onChange={() => toggleField('takeProfit')}
              label=""
            />
            <div style={{ flex: 1 }}>
              <Form.Label style={{ color: 'white' }}>Take Profit (USDT)</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="takeProfit"
                value={conditions.takeProfit}
                onChange={handleChange}
                disabled={!enabledFields.takeProfit}
              />
              <Form.Text style={{ color: '#cbd5e0' }}>
                Bot will stop when profit reaches this amount
              </Form.Text>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" style={formGroupStyle}>
            <Form.Check
              type="checkbox"
              checked={enabledFields.stopLoss}
              onChange={() => toggleField('stopLoss')}
              label=""
            />
            <div style={{ flex: 1 }}>
              <Form.Label style={{ color: 'white' }}>Stop Loss (USDT)</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="stopLoss"
                value={conditions.stopLoss}
                onChange={handleChange}
                disabled={!enabledFields.stopLoss}
              />
              <Form.Text style={{ color: '#cbd5e0' }}>
                Bot will stop when loss reaches this amount
              </Form.Text>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" style={formGroupStyle}>
            <Form.Check
              type="checkbox"
              checked={enabledFields.upperLimit}
              onChange={() => toggleField('upperLimit')}
              label=""
            />
            <div style={{ flex: 1 }}>
              <Form.Label style={{ color: 'white' }}>Upper Price Limit</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="upperLimit"
                value={conditions.upperLimit}
                onChange={handleChange}
                disabled={!enabledFields.upperLimit}
              />
              <Form.Text style={{ color: '#cbd5e0' }}>
                Bot will pause when price reaches this upper limit
              </Form.Text>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" style={formGroupStyle}>
            <Form.Check
              type="checkbox"
              checked={enabledFields.lowerLimit}
              onChange={() => toggleField('lowerLimit')}
              label=""
            />
            <div style={{ flex: 1 }}>
              <Form.Label style={{ color: 'white' }}>Lower Price Limit</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="lowerLimit"
                value={conditions.lowerLimit}
                onChange={handleChange}
                disabled={!enabledFields.lowerLimit}
              />
              <Form.Text style={{ color: '#cbd5e0' }}>
                Bot will pause when price reaches this lower limit
              </Form.Text>
            </div>
          </Form.Group>

          <Form.Group className="mb-3" style={formGroupStyle}>
            <Form.Check
              type="checkbox"
              checked={enabledFields.runTime}
              onChange={() => toggleField('runTime')}
              label=""
            />
            <div style={{ flex: 1 }}>
              <Form.Label style={{ color: 'white' }}>Runtime (hours)</Form.Label>
              <Form.Control
                type="number"
                step="0.1"
                name="runTime"
                value={conditions.runTime}
                onChange={handleChange}
                disabled={!enabledFields.runTime}
              />
              <Form.Text style={{ color: '#cbd5e0' }}>
                Bot will stop after running for this many hours
              </Form.Text>
            </div>
          </Form.Group>

          <Button variant="primary" type="submit">
            {isEditing ? 'Update Conditions' : 'Set Conditions'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BotConditionsModal;