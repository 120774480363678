import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import axiosInstance from '../../services/axiosConfig';
import styled from 'styled-components';

const DarkSelect = styled(Form.Select)`
  background-color: #2c3e50;
  color: #fff;
  border: 1px solid #495057;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1em auto;
  padding-right: 3em;
  &:focus {
    background-color: #2c3e50;
    color: #fff;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }

  option {
    background-color: #34495e;
    color: #fff;
  }
`;

const ApiSelector = ({ onApiSelect }) => {
  const [apis, setApis] = useState([]);
  const [selectedApiId, setSelectedApiId] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchApis();
  }, []);

  const fetchApis = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get('/api/user/apis', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-auth-token': token
        },
        withCredentials: true
      });
      setApis(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching APIs:', error);
      setError('Failed to fetch APIs. Make sure you are logged in.');
    }
  };

  const handleApiSelect = (e) => {
    const apiId = e.target.value;
    setSelectedApiId(apiId);
    const selected = apis.find(api => api._id === apiId);
    onApiSelect(selected || null);
  };

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  return (
    <Form.Group controlId="api" className="mb-2">
      <Form.Label className="mb-1">API:</Form.Label>
      <DarkSelect
        size="sm"
        value={selectedApiId}
        onChange={handleApiSelect}
      >
        <option value="">Select API</option>
        {apis.map(api => (
          <option key={api._id} value={api._id}>
            {api.name} ({api.exchange} - {api.environment})
          </option>
        ))}
      </DarkSelect>
    </Form.Group>
  );
};

export default ApiSelector;