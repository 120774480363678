import axios from 'axios';

const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

const axiosInstance = axios.create({
  baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    console.log('Interceptor - Token from localStorage:', token);
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    console.log('Interceptor - Request headers:', config.headers);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;