import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PersonCircle, List, X } from 'react-bootstrap-icons';
import { useAuth } from '../../AuthContext';
import VirtualBalance from './VirtualBalance';

function Header() {
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="py-2">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="me-0">
            <h4 className="logo m-0">SmartPulse</h4>
          </Navbar.Brand>
          <div className="d-flex align-items-center ms-auto order-lg-last">
            {isLoggedIn && <VirtualBalance />}
            <div className="ms-3 d-flex align-items-center">
              {isLoggedIn ? (
                <NavDropdown title={<PersonCircle size={20} />} id="user-dropdown" align="end">
                  <NavDropdown.Item as={Link} to="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <Nav.Link as={Link} to="/login" className="me-2">Log In</Nav.Link>
                  <Nav.Link as={Link} to="/register" className="btn-primary">Sign Up</Nav.Link>
                </>
              )}
            </div>
            <button
              className="navbar-toggler border-0 p-0 ms-3 d-lg-none"
              type="button"
              onClick={toggleNav}
            >
              <List size={24} color="white" />
            </button>
          </div>
          <Navbar.Collapse id="navbar-nav" className="order-lg-1">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/arbitrage">Arbitrage</Nav.Link>
              <Nav.Link as={Link} to="/tradingbots">TradingBots</Nav.Link>
              <Nav.Link as={Link} to="/strategies">Strategies</Nav.Link>
              <Nav.Link as={Link} to="/help">Help</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {isOpen && (
        <div className="position-fixed top-0 start-0 w-100 h-100 text-light d-lg-none" style={{zIndex: 1030, backgroundColor: '#212529'}}>
          <div className="container h-100 d-flex flex-column">
            <div className="d-flex justify-content-end mt-3 mb-4">
              <button
                className="btn text-light p-0"
                onClick={toggleNav}
              >
                <X size={24} />
              </button>
            </div>
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/" className="text-light" onClick={toggleNav}>Home</Nav.Link>
              <Nav.Link as={Link} to="/arbitrage" className="text-light" onClick={toggleNav}>Arbitrage</Nav.Link>
              <Nav.Link as={Link} to="/tradingbots" className="text-light" onClick={toggleNav}>TradingBots</Nav.Link>
              <Nav.Link as={Link} to="/strategies" className="text-light" onClick={toggleNav}>Strategies</Nav.Link>
              <Nav.Link as={Link} to="/help" className="text-light" onClick={toggleNav}>Help</Nav.Link>
            </Nav>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;