// BotCardComponents.js
import React, { useState } from 'react';
import BotConditionsModal from '../forms/BotConditionsModal';

const LongGridBotCard = ({ botData, getColorStyle, isOwner, showAllBots, handleCopyBot,}) => (
  <div className="bot-grid">
    <div className="grid-item">
      <span id='thy' style={{ fontSize: '1.2em', fontWeight: '400' }}>{botData.symbol}</span>
      <span style={getColorStyle(botData.leverage, 'leverage')}>x{botData.leverage}</span>
    </div>
    <div className="grid-item" style={getColorStyle(botData.buys, 'buys')}>Buys: {botData.buys}</div>
    <div className="grid-item" style={getColorStyle(botData.sells, 'sells')}>Sells: {botData.sells}</div>
    <div className="grid-item">PosSize: {botData.posSize}</div>
    <div className="grid-item">Grid No: {botData.gridNo}</div>
    <div className="grid-item" style={getColorStyle(botData.totalProfit, 'pnl')}>
      Pnl: {botData.totalProfit}
    </div>
    <div className="grid-item roe" style={getColorStyle((botData.totalProfit / botData.balance) * 100, 'roe')}>
      ROE: {((botData.totalProfit / botData.balance) * 100).toFixed(2)}%
    </div>
    <div className="grid-item">Runtime: {botData.runtime}</div>
    <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>
      MarkPrice: {botData.currentPrice}
    </div>
    <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>EntryPrice: {botData.entryPrice}</div>
    <div className="grid-item" style={getColorStyle(botData.liqPrice, 'liqPrice')}>Liq.Price: {botData.liqPrice}</div>
    {isOwner && !showAllBots ? (
      <button className="grid-item btn btn-info btn-sm">Edit</button>
    ) : (
      <button className="grid-item btn btn-info btn-sm" onClick={handleCopyBot}>Copy</button>
    )}
  </div>
);

const ShortGridBotCard = ({ botData, getColorStyle, isOwner, showAllBots, handleCopyBot, handleStopBot }) => (
  <div className="bot-grid">
    <div className="grid-item">
      <span id='thy' style={{ fontSize: '1.2em', fontWeight: '400' }}>{botData.symbol}</span>
      <span style={getColorStyle(botData.leverage, 'leverage')}>x{botData.leverage}</span>
    </div>
    <div className="grid-item" style={getColorStyle(botData.buys, 'buys')}>Buys: {botData.buys}</div>
    <div className="grid-item" style={getColorStyle(botData.sells, 'sells')}>Sells: {botData.sells}</div>
    <div className="grid-item">PosSize: {botData.posSize}</div>
    <div className="grid-item">Grid No: {botData.gridNo}</div>
    <div className="grid-item" style={getColorStyle(botData.totalProfit, 'pnl')}>
      Pnl: {botData.totalProfit}
    </div>
    <div className="grid-item roe" style={getColorStyle((botData.totalProfit / botData.balance) * 100, 'roe')}>
      ROE: {((botData.totalProfit / botData.balance) * 100).toFixed(2)}%
    </div>
    <div className="grid-item">Runtime: {botData.runtime}</div>
    <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>
      MarkPrice: {botData.currentPrice}
    </div>
    <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>EntryPrice: {botData.entryPrice}</div>
    <div className="grid-item" style={getColorStyle(botData.liqPrice, 'liqPrice')}>Liq.Price: {botData.liqPrice}</div>
    {isOwner && !showAllBots ? (
      <button className="grid-item btn btn-info btn-sm">Edit</button>
    ) : (
      <button className="grid-item btn btn-info btn-sm" onClick={handleCopyBot}>Copy</button>
    )}
  </div>
);

const VirtualGridBotCard = ({ botData, getColorStyle, isOwner, showAllBots, handleCopyBot, handleStopBot }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <div className="bot-grid">
        <div className="grid-item">
          <span id='thy' style={{ fontSize: '1.2em', fontWeight: '400' }}>{botData.symbol}</span>
          <span style={getColorStyle(botData.leverage, 'leverage')}>x{botData.leverage}</span>
        </div>
        <div className="grid-item" style={getColorStyle(botData.buys, 'buys')}>Buys: {botData.buys}</div>
        <div className="grid-item" style={getColorStyle(botData.sells, 'sells')}>Sells: {botData.sells}</div>
        <div className="grid-item">PosSize: {botData.posAmt}</div>
        <div className="grid-item">Grid No: {botData.gridNo}</div>
        <div className="grid-item" style={getColorStyle(botData.totalProfit, 'pnl')}>
          Pnl: {botData.totalProfit}
        </div>
        <div className="grid-item roe" style={getColorStyle((botData.totalProfit / botData.balance) * 100, 'pnl')}>
          ROE: {((botData.totalProfit / botData.neededAmount) * 100).toFixed(2)}%
        </div>
        <div className="grid-item">Runtime: {botData.elapsedTime}</div>
        <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>
          MarkPrice: {botData.currentPrice}
        </div>
        <div className="grid-item" style={getColorStyle(botData.currentPrice, 'markPrice')}>EntryPrice: {botData.entryPrice}</div>
        <div className="grid-item" style={getColorStyle(botData.liqPrice, 'liqPrice')}>Liq.Price: {botData.liqPrice}</div>
        {isOwner && !showAllBots ? (
          <button
            className="grid-item btn btn-info btn-sm"
            onClick={() => setShowEditModal(true)}
          >
            Edit
          </button>
        ) : (
          <button className="grid-item btn btn-info btn-sm" onClick={handleCopyBot}>
            Copy
          </button>
        )}
      </div>

      <BotConditionsModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        botId={botData.botId}
        isEditing={true}
      />
    </>
  );
};

export { LongGridBotCard, ShortGridBotCard, VirtualGridBotCard };