import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import axiosInstance from '../services/axiosConfig';

const VirtualBalance = () => {
  const [balance, setBalance] = useState({
    real: 0.00,
    virtual: 0.00
  });
  const [selectedAccount, setSelectedAccount] = useState('virtual');

  const fetchBalance = async () => {
    try {
      const response = await axiosInstance.get('/bot/balance');
      setBalance(prevBalance => ({
        ...prevBalance,
        virtual: response.data.balance
      }));
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const handleReset = async (e) => {
    e.stopPropagation();
    try {
      await axiosInstance.post('/bot/reset-balance');
      fetchBalance();
    } catch (error) {
      console.error('Error resetting balance:', error);
    }
  };

  useEffect(() => {
  fetchBalance();
  const intervalId = setInterval(fetchBalance, 1000); // Update every 5 seconds instead of 30
  return () => clearInterval(intervalId);
}, []);

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
  };

  return (
    <Dropdown align="end">
      <Dropdown.Toggle variant="dark" id="balance-dropdown">
        {balance[selectedAccount]} USD
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => handleAccountSelect('real')}
          active={selectedAccount === 'real'}
        >
          <div>Real Account</div>
          <div className="text-muted">{balance.real} USD</div>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleAccountSelect('virtual')}
          active={selectedAccount === 'virtual'}
        >
          <div>Virtual Account</div>
          <div className="text-muted">{balance.virtual} USD</div>
          <button className="reset-button mt-2" onClick={handleReset}>
            Reset Balance
          </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VirtualBalance;
