import React, { useState } from 'react';
import Bot1card from './bot1card';
import Scrollbar from '../../scrollbar/Scrollbar';
import './ActiveBotsList.css';

const ActiveBotsList = ({ activeBots, selectedBotId, onBotSelect }) => {
  const [activeTab, setActiveTab] = useState('myBots');
  const [myBotsSubTab, setMyBotsSubTab] = useState('running');

  const filteredBots = activeBots.filter(bot => {
    if (activeTab === 'myBots') {
      if (myBotsSubTab === 'running') {
        return bot.isOwner && bot.isRunning;
      } else {
        return bot.isOwner && !bot.isRunning;
      }
    } else {
      return bot.isRunning; // All Bots tab shows only running bots
    }
  });

  return (
    <div className="card bg-dark text-light" style={{ height: '660px' }}>
      <div className="card-body d-flex flex-column" style={{ height: '100%' }}>
        <h4 className="card-title mb-3">Active Bots</h4>
        <div className="bot-tabs mb-3">
          <button
            className={`btn btn-primary ${activeTab === 'myBots' ? 'active' : ''}`}
            onClick={() => setActiveTab('myBots')}
          >
            My Bots
          </button>
          <button
            className={`btn btn-primary ${activeTab === 'allBots' ? 'active' : ''}`}
            onClick={() => setActiveTab('allBots')}
          >
            All Bots
          </button>
        </div>
        {activeTab === 'myBots' && (
          <div className="sub-tabs mb-3">
            <button
              className={`btn ${myBotsSubTab === 'running' ? 'active' : 'inactive'}`}
              onClick={() => setMyBotsSubTab('running')}
            >
              Running
            </button>
            <button
              className={`btn ${myBotsSubTab === 'closed' ? 'active' : 'inactive'}`}
              onClick={() => setMyBotsSubTab('closed')}
            >
              Closed
            </button>
          </div>
        )}
        <Scrollbar style={{ flexGrow: 1, minHeight: 0 }}>
          <div className="bot-list">
            {filteredBots.length > 0 ? (
              filteredBots.map(bot => (
                <Bot1card
                  key={bot.botId}
                  botId={bot.botId}
                  isRunning={bot.isRunning}
                  isOwner={bot.isOwner}
                  showAllBots={activeTab === 'allBots'}
                  onSelect={onBotSelect}
                  isSelected={selectedBotId === bot.botId}
                />
              ))
            ) : (
              <div>No bots found.</div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ActiveBotsList;