import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AuthProvider } from './AuthContext';
// Import components
import Header from './components/Homepage/Header';
import Home from './components/Homepage/Home';
import Arbitrage from './components/Arbitrage/Arbitrage';
import TradingBots from './components/TradingBots/main/TradingBots';
import Strategies from './components/strategies/Strategies';
import Help from './components/Help/Help';
import Login from './components/auth/Login';
import Register from './components/auth/Register';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App bg-dark text-light min-vh-100 d-flex flex-column">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/arbitrage" element={<Arbitrage />} />
              <Route path="/tradingbots" element={<TradingBots />} />
              <Route path="/strategies" element={<Strategies />} />
              <Route path="/help" element={<Help />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
            </Routes>
          </main>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;