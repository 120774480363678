import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import './ActiveBotCard.css';
import { useAuth } from '../../../AuthContext';
import axiosInstance from '../../services/axiosConfig';
import { LongGridBotCard, ShortGridBotCard, VirtualGridBotCard } from './BotCardComponents';

const Bot1card = ({ botId, isRunning, isOwner, showAllBots, onSelect }) => {
  const [botData, setBotData] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const { isLoggedIn } = useAuth();

  const handleBotUpdate = useCallback((data) => {
    console.log(`Received update for bot ${botId}:`, data);
    if (data.botId === botId) {
      console.log(`Updating state for bot ${botId}`);
      setBotData(data);
    }
  }, [botId]);

  const getColorStyle = (value, type) => {
    let style = {};
    switch (type) {
      case 'pnl':
      case 'roe':
        style.color = value >= 0 ? '#74a700' : '#f6465d';
        break;
      case 'markPrice':
        style.color = botData?.entryPrice && value > botData.entryPrice ? '#74a700' : '#f6465d';
        break;
      case 'liqPrice':
        style.color = '#f6465d';
        break;
      case 'buys':
        style.color = '#74a700';
        break;
      case 'sells':
        style.color = '#f6465d';
        break;
      case 'leverage':
        style.color = '#f7a600';
        style.fontSize = '0.8em';
        break;
      default:
        break;
    }
    return style;
  };

  useEffect(() => {
    console.log(`Bot1card component mounted for bot ${botId}, isRunning: ${isRunning}`);
    if (!isLoggedIn || !isRunning) {
      console.log('User is not logged in or bot is not running');
      return;
    }

    console.log('Connecting to socket server');
    const socket = io(axiosInstance.defaults.baseURL, {
      auth: {
        token: localStorage.getItem('token')
      }
    });

    socket.on('connect', () => {
      console.log(`Socket connected for bot ${botId}`);
      setSocketConnected(true);
      console.log(`Subscribing to updates for bot ${botId}`);
      socket.emit('subscribe', botId);
    });

    socket.on('connect_error', (error) => {
      console.error(`Socket connection error for bot ${botId}:`, error);
    });

    socket.on('botUpdate', handleBotUpdate);

    return () => {
      console.log(`Cleaning up socket connection for bot ${botId}`);
      socket.off('botUpdate', handleBotUpdate);
      socket.disconnect();
    };
  }, [botId, isLoggedIn, isRunning, handleBotUpdate]);

  const handleStopBot = async () => {
    if (!isLoggedIn || !isOwner) {
      console.log('User must be logged in and be the owner to stop a bot');
      return;
    }
    try {
      console.log(`Attempting to stop bot ${botId}`);
      const response = await axiosInstance.post('/bot/stop-bot', { botId });
      if (response.data.success) {
        console.log(`Bot ${botId} stopped successfully`);
        // Handle bot stopped (e.g., remove from list or update UI)
      } else {
        console.error(`Failed to stop bot ${botId}:`, response.data.error);
      }
    } catch (error) {
      console.error(`Error stopping bot ${botId}:`, error.response?.data || error.message);
    }
  };

  const handleCopyBot = () => {
    console.log(`Copying configuration for bot ${botId}`);
    // Implement copy functionality here
  };
const renderBotCard = () => {
    switch (botData.botType) {
      case 'long-grid':
        return <LongGridBotCard
          botData={botData}
          getColorStyle={getColorStyle}
          isOwner={isOwner}
          showAllBots={showAllBots}
          handleCopyBot={handleCopyBot}
          handleStopBot={handleStopBot}
        />;
      case 'short-grid':
        return <ShortGridBotCard
          botData={botData}
          getColorStyle={getColorStyle}
          isOwner={isOwner}
          showAllBots={showAllBots}
          handleCopyBot={handleCopyBot}
          handleStopBot={handleStopBot}
        />;
      case 'virtual-grid':
        return <VirtualGridBotCard
          botData={botData}
          getColorStyle={getColorStyle}
          isOwner={isOwner}
          showAllBots={showAllBots}
          handleCopyBot={handleCopyBot}
          handleStopBot={handleStopBot}
        />;
      default:
        return <div>Unknown bot type</div>;
    }
  };

  if (!isLoggedIn && !showAllBots) {
    return <div className="bot-card">Please log in to view bot data.</div>;
  }

  if (!isRunning) {
    return (
      <div className="bot-card">
        <div className="bot-header">
          <h5>Bot {botId}</h5>
          <span className="bot-id">Status: Not Running</span>
        </div>
        <div className="bot-grid">
          {isOwner && (
            <button className="grid-item btn btn-success btn-sm" onClick={() => console.log('Start bot clicked')}>Start Bot</button>
          )}
        </div>
      </div>
    );
  }

  if (!botData) {
    return <div className="bot-card">Loading data for bot {botId}...</div>;
  }

  return (
    <div className="bot-card" onClick={() => isOwner && onSelect(botId)}>
      <div className="bot-header">
        <h5>{botData.exchange.charAt(0).toUpperCase() + botData.exchange.slice(1)} - {botData.botType} </h5>
        <span className="bot-id">Bot ID: {botData.botId}</span>
      </div>
      {renderBotCard()}
      {isOwner && !showAllBots && (
        <div>
          <button className="grid-item btn btn-warning btn-sm" id="stpbtn" onClick={(e) => {
            e.stopPropagation();
            handleStopBot();
          }}>Stop Bot</button>
        </div>
      )}
    </div>
  );
};

export default Bot1card;